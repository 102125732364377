<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App"
});

</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
body{
  font-family: 'Open Sans', serif;
  margin: 0;
  background-color: #2a2e33;
  color: white;
}
.el-header {
  background-color: #1a2029;
  color: #333;
  line-height: 60px;
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  z-index: 1;
}
.el-aside {
  position: fixed;
  left: 0;
  /*margin-top: 60px;*/
  background-color: #2a2e33;
  height: 100%;
  width: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;    /* スクロール：IE,Edge対応 */
  scrollbar-width: none; /* スクロール：Firefox対応 */
}
.el-aside::-webkit-scrollbar {  /* スクロール：Chrome,Safari対応 */
  display:none;
}
.app-main {
  margin-left: 300px;
}

.el-aside {
  color: #333;
}
</style>
