<template>
  <el-header style="font-size: 12px; color: #e6e6e6">
    <el-row :gutter="20">
      <el-col
        :span="8"
        style="font-size: 16px; font-weight: bold;"
      >
        <div
          style="display: block;"
        >
          <img
            alt="Vue logo"
            src="@/assets/birdroid.svg"
            width="40"
            height="40"
            style="vertical-align: middle;"
          >
          Online Dev Tools
        </div>
      </el-col>
      <el-col
        :span="16"
        style="text-align: right;"
      >
        <el-dropdown>
          <i
            class="el-icon-setting"
            style="margin-right: 15px"
          />
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>View</el-dropdown-item>
              <el-dropdown-item>Add</el-dropdown-item>
              <el-dropdown-item>Delete</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span>Y</span>
      </el-col>
    </el-row>
  </el-header>
</template>

<script>
export default {
name: "AppHeader"
}
</script>

<style scoped>

</style>