
//import { defineComponent, computed } from 'vue'
import { defineComponent} from 'vue'
//import { useRoute, useRouter } from 'vue-router'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'AppMain',
  props: {
    // transitionName: {
    //   type: String,
    //   default: 'el-fade-in',
    // },
  },
  setup () {
    // const { route, store } = useEnhancer()
    // const cachedViews = computed(() => store.getters.cachedViews)
    const route = useRoute()
    return {
      route,
      //cachedViews,
    }
  },
})
