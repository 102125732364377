import { createApp } from 'vue';
import { createHead } from '@vueuse/head'
import App from '@/App.vue';

import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
//import locale from "element-plus/lib/locale/lang/ja";
import router from '@/router'
import VueGtag from "vue-gtag-next";

const app = createApp(App);
const head = createHead()
//app.use(ElementPlus, { size: "small", locale: locale.name });
app.use(ElementPlus);
app.use(router);
app.use(VueGtag, { property: { id: 'G-0HM9G01J2Y', router }});
app.use(head);

router.isReady()
    .then(() => app.mount('#app'))
// app.mount('#app')
//
// createApp(App)
//     .use(ElementPlus, { size: "small", locale: locale.default })
//     .use(router)
//     .mount('#app')
