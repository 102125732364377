/**
 * @file Constant routes
 * @module router/routes
 */

import type { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/redirect',
  //   component: Layout,
  //   meta: {
  //     hidden: true,
  //   },
  //   children: [
  //     {
  //       path: '/redirect/:path(.*)',
  //       component: () => import('@/views/common/redirect.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/not-found',
  //   name: 'NotFound',
  //   meta: {
  //     hidden: true,
  //   },
  //   component: () => import('@/views/common/not-found.vue'),
  // },
  //
  // {
  //   path: '/',
  //   redirect: '/dashboard',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/dashboard',
  //       name: 'Dashboard',
  //       component: () => import('@/views/dashboard/index.vue'),
  //     },
  //   ],
  // },
  {
    path: '/',
    redirect: '/text/encode',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/index.vue'),
      },
      {
        path: '/time/timestamp',
        name: 'Timestamp',
        meta: { title: 'Timestamp', icon: 'Timestamp', affix: true },
        component: () => import('@/views/text/count.vue'),
      },
      {
        path: '/time/time-span-calculator',
        name: 'TimeSpanCalculator',
        meta: { title: 'TimeSpanCalculator', icon: 'TimeSpanCalculator', affix: true },
        component: () => import('@/views/text/time/time-span-calculator.vue'),
      },
      {
        path: '/text/count',
        name: 'TextCount',
        meta: { title: 'TextCount', icon: 'TextCount', affix: true },
        component: () => import('@/views/text/count.vue'),
      },
      {
        path: '/text/encode',
        name: 'TextEncode',
        component: () => import('@/views/text/encode.vue'),
      },
      {
        path: '/text/replace',
        name: 'TextReplace',
        component: () => import('@/views/text/replace.vue'),
      },
      {
        path: '/text/case',
        name: 'TextChangeCase',
        component: () => import('@/views/text/case.vue'),
      },
      {
        path: '/text/string/random',
        name: 'StringRandom',
        component: () => import('@/views/text/string/random.vue'),
      },
      {
        path: '/text/json-format',
        name: 'JsonFormat',
        component: () => import('@/views/text/json-format.vue'),
      },
      {
        path: '/text/json-pretty',
        name: 'JsonPretty',
        component: () => import('@/views/text/json-pretty.vue'),
      },
      // {
      //   path: '/web/resolve',
      //   name: 'WebResolve',
      //   component: () => import('@/views/web/resolve.vue'),
      // },
    ],
  },

  {
    path: '/:pathMatch(.*)',
    name: 'Fallback',
    redirect: { name: 'NotFound' },
    meta: {
      hidden: true,
    },
  },
]


export default routes