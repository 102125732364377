<template>
  <el-aside>
    <el-menu
      default-active="text-encode"
      class="el-menu-vertical-demo"
      background-color="#2a2e33"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
    >
      <el-menu-item
        index="home"
        :route="{ name:'Index'}"
      >
        <i class="el-icon-s-home" />
        <span>HOME</span>
      </el-menu-item>
      <el-submenu index="text-util">
        <template #title>
          <i class="el-icon-notebook-1" />
          <span>Text</span>
        </template>
        <el-menu-item-group title="Time">
          <el-menu-item
            index="timestamp"
            :route="{ name:'Timestamp'}"
            disabled
          >
            <span>UnixTime</span>
          </el-menu-item>
          <el-menu-item
            index="TimeSpanCalculator"
            :route="{ name:'TimeSpanCalculator'}"
          >
            <span>Time Span Calculator</span>
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group title="String">
          <el-menu-item
            index="text-count"
            :route="{ name:'TextCount' }"
          >
            <span>Count (Byte)</span>
          </el-menu-item>
          <el-menu-item
            index="text-replace"
            :route="{ name:'TextReplace' }"
          >
            <span>Replace</span>
          </el-menu-item>
          <el-menu-item
            index="text-encode"
            :route="{ name:'TextEncode' }"
          >
            <span>Encode / Decode</span>
          </el-menu-item>
          <el-menu-item
            index="text-string-random"
            :route="{ name:'StringRandom' }"
          >
            <span>Random String</span>
          </el-menu-item>
          <el-menu-item
            index="text-change-case"
            :route="{ name:'TextChangeCase' }"
          >
            <span>Change Case</span>
          </el-menu-item>
          <el-menu-item
            index="hash-calculator"
            :route="{ name:'HashCalculator' }"
            disabled
          >
            <span>Hash Calculator</span>
          </el-menu-item>
          <el-menu-item
            index="decimal-converter"
            :route="{ name:'DecimalConverter' }"
            disabled
          >
            <span>Decimal Converter</span>
          </el-menu-item>
        </el-menu-item-group>

        <el-submenu
          index="json-util"
          disabled
        >
          <template #title>
            JSON
          </template>
          <el-menu-item
            index="json-format"
            :route="{ name:'JsonFormat' }"
          >
            Format
          </el-menu-item>
          <el-menu-item
            index="json-pretty"
            :route="{ name:'JsonPretty' }"
          >
            Pretty / UnPretty
          </el-menu-item>
        </el-submenu>
      </el-submenu>
      <el-submenu
        index="image"
        disabled
      >
        <template #title>
          <i class="el-icon-camera" />
          <span>Image</span>
        </template>
        <el-menu-item-group title="Code">
          <el-menu-item index="2-1">
            <span>QRCode</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="other">
        <template #title>
          <i class="el-icon-notebook-1" />
          <span>Other</span>
        </template>
        <el-menu-item
          index="environment"
          disabled
        >
          <span>Environment</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        index="3"
        disabled
      >
        <i class="el-icon-menu" />
        <span>Color Code</span>
      </el-menu-item>
      <el-menu-item
        index="3"
        disabled
      >
        <i class="el-icon-menu" />
        <span>AWS</span>
      </el-menu-item>
      <el-menu-item
        index="4"
        disabled
      >
        <i class="el-icon-document" />
        <span>Navigator Four</span>
      </el-menu-item>
      <el-menu-item
        index="5"
        disabled
      >
        <i class="el-icon-setting" />
        <span>Navigator Five</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
//import { useRoute, useRouter } from 'vue-router'
//import { useRouter } from 'vue-router'

export default defineComponent({
  name: "AppMenu",
  components: {},
  methods: {
    // handleSelect(key, keyPath) {
    //   console.log(key);
    //   const router = useRouter()
    //   //router.push({ name: 'string-length'})
    // }
    handleOpen(key:string, keyPath:string) {
      console.log({event:"handleOpen", key:key, keyPath:keyPath});
    },
    handleClose(key:string, keyPath:string) {
      console.log({event:"handleClose", key:key, keyPath:keyPath});
    },
    handleSelect(key:string, keyPath:string) {
      console.log({event:"handleSelect", key:key, keyPath:keyPath});
    }
  }
})
</script>

<style scoped>

</style>