<template>
  <app-header />
  <el-container style="margin-top:60px; border: none">
    <app-menu />
    <app-main />
  </el-container>
</template>

<script lang="ts">
//import { defineComponent, computed } from 'vue'
import { defineComponent, ref} from 'vue'

//import { useEnhancer } from '@/enhancers'
import AppHeader from './components/AppHeader.vue'
import AppMenu from './components/AppMenu.vue'
import AppMain from './components/AppMain.vue'

export default defineComponent({
  name: 'Layout',
  components: {
    AppHeader,
    AppMenu,
    AppMain,
  },
  setup () {
    // const { store } = useEnhancer()
    // const wrapperClass = computed(() => ({
    //   'is-sidebar-open': store.getters.sidebar.isOpen,
    //   'is-sidebar-close': !store.getters.sidebar.isOpen,
    // }))
    // return {
    //   wrapperClass,
    // }
    return {
      input: ref('http://')
    }
  },
})
</script>

<style scoped>

</style>