
import { defineComponent } from 'vue'
//import { useRoute, useRouter } from 'vue-router'
//import { useRouter } from 'vue-router'

export default defineComponent({
  name: "AppMenu",
  components: {},
  methods: {
    // handleSelect(key, keyPath) {
    //   console.log(key);
    //   const router = useRouter()
    //   //router.push({ name: 'string-length'})
    // }
    handleOpen(key:string, keyPath:string) {
      console.log({event:"handleOpen", key:key, keyPath:keyPath});
    },
    handleClose(key:string, keyPath:string) {
      console.log({event:"handleClose", key:key, keyPath:keyPath});
    },
    handleSelect(key:string, keyPath:string) {
      console.log({event:"handleSelect", key:key, keyPath:keyPath});
    }
  }
})
